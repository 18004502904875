//@import "bootstrap/bootstrap";
//@import "../bower/bootstrap/less/bootstrap";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
	font-weight: 300;
}

.jqtree-element {
	min-height: 30px;
	/*padding: 3px;*/
	padding: 3px 0;
}

table.dataTable tbody tr.selected {
	background-color: #97BDD6 !important;
   background: -webkit-gradient(linear, left top, left bottom, from(#BEE0F5), to(#89AFCA)) !important;
   background: -moz-linear-gradient(top, #BEE0F5, #89AFCA) !important;
   background: -ms-linear-gradient(top, #BEE0F5, #89AFCA) !important;
   background: -o-linear-gradient(top, #BEE0F5, #89AFCA) !important;
   text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7) !important;
}

.select2-container .select2-selection--single {
	height: 34px !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
	background-color: #3c8dbc !important;
}


.folder_node_label {
	display: inline-block;
	// margin-left: 15px;

	text-align: left;
	vertical-align: middle;
}

.folder_node_label > .node_attributes {
	display: inline-block;
}

.folder_node_label > .node_attributes > * {
	vertical-align: middle;
}


.document_node_label {
	display: inline-block;
	// margin-left: 15px;

	text-align: left;
	vertical-align: middle;
}

.document_node_label > .node_attributes {
	display: inline-block;
}

.document_node_label > .node_attributes > * {
	vertical-align: middle;
}

/* typeahead */
//
// .twitter-typeahead .tt-query,
// .twitter-typeahead .tt-hint {
// 	margin-bottom: 0;
// }
// .tt-hint {
// 	display: block;
// 	width: 100%;
// 	height: 38px;
// 	padding: 8px 12px;
// 	font-size: 14px;
// 	line-height: 1.428571429;
// 	color: #999;
// 	vertical-align: middle;
// 	background-color: #ffffff;
// 	border: 1px solid #cccccc;
// 	border-radius: 4px;
// 	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
// 	      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
// 	-webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
// 	      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
// }
// .tt-dropdown-menu {
// 	min-width: 160px;
// 	margin-top: 2px;
// 	padding: 5px 0;
// 	background-color: #ffffff;
// 	border: 1px solid #cccccc;
// 	border: 1px solid rgba(0, 0, 0, 0.15);
// 	border-radius: 4px;
// 	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
// 	      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
// 	background-clip: padding-box;
//
// }
// .tt-suggestion {
// 	display: block;
// 	padding: 3px 20px;
// }
// .tt-suggestion.tt-is-under-cursor {
// 	color: #fff;
// 	background-color: #428bca;
// }
// .tt-suggestion.tt-is-under-cursor a {
// 	color: #fff;
// }
// .tt-suggestion p {
// 	margin: 0;
// }


/* scaffolding */
/* ----------- */


.tt-menu,
.gist {
  text-align: left;
}

.typeahead,
.tt-query,
.tt-hint {
  // width: 396px;
  // height: 30px;
  // padding: 8px 12px;
  font-size: 20px;
  line-height: 30px;
  // border: 2px solid #ccc;
  // -webkit-border-radius: 8px;
  //    -moz-border-radius: 8px;
  //         border-radius: 8px;
  outline: none;
}

.typeahead {
	height: 44px;
	overflow: hidden;
	background-color: #fff;
}

.typeahead:focus {
  // border: 2px solid #0097cf;
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

.tt-menu {
  // width: 422px;
	max-width: 100%;
  margin: 0;
  padding: 1px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  // -webkit-border-radius: 8px;
  //    -moz-border-radius: 8px;
  //         border-radius: 8px;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
     -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
          box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.tt-suggestion {
	// padding: 3px 20px;
  padding: 10px 10px;
  font-size: 18px;
  line-height: 24px;
}

.tt-suggestion > * {
	vertical-align: middle;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #0097cf;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;

}

.tt-suggestion p {
  margin: 0;
}

.gist {
  font-size: 14px;
}

/* example specific styles */
/* ----------------------- */

#custom-templates .empty-message {
  padding: 5px 10px;
 text-align: center;
}

#scrollable-dropdown-menu .tt-menu {
  max-height: 150px;
  overflow-y: auto;
}

#rtl-support .tt-menu {
  text-align: right;
}

.tt-highlight {
	font-weight: inherit;
	// background-color: yellow;
	color: red;
}

.login-box, .register-box {
    width: 360px;
    margin: 3% auto;
}

.login-logo, .register-logo {
    font-size: 35px;
    text-align: center;
		margin: 40px;
    font-weight: 300;
}

.login-page, .register-page {
    background: white;
}

.login-box-body, .register-box-body {
    background: #fff;
    padding: 20px;
    border-top: 0;
    color: #666;
		border: 1px solid #d2d6de;
}

.content-wrapper, .right-side {
    min-height: 100%;
    background-color: white;
    z-index: 800;
}

.sidebar-menu, .main-sidebar .user-panel, .sidebar-menu > li.header {
    white-space: normal;
    overflow: hidden;
}

.public-content-wrapper {
	margin-left: 0;
}

.public-main-footer {
	margin-left: 0;
}

.logo_auth {
  max-width: 300px;
}



.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-left: -45px;
  margin-top: -25px;
}

.fancybox__content {
  padding: 0;
}

video:not(.vjs-tech) {
  display:none;
}