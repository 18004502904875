body,
label,
.checkbox label {
  font-weight: 300;
}
.jqtree-element {
  min-height: 30px;
  /*padding: 3px;*/
  padding: 3px 0;
}
table.dataTable tbody tr.selected {
  background-color: #97BDD6 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#BEE0F5), to(#89AFCA)) !important;
  background: -moz-linear-gradient(top, #BEE0F5, #89AFCA) !important;
  background: -ms-linear-gradient(top, #BEE0F5, #89AFCA) !important;
  background: -o-linear-gradient(top, #BEE0F5, #89AFCA) !important;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7) !important;
}
.select2-container .select2-selection--single {
  height: 34px !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #3c8dbc !important;
}
.folder_node_label {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.folder_node_label > .node_attributes {
  display: inline-block;
}
.folder_node_label > .node_attributes > * {
  vertical-align: middle;
}
.document_node_label {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.document_node_label > .node_attributes {
  display: inline-block;
}
.document_node_label > .node_attributes > * {
  vertical-align: middle;
}
/* typeahead */
/* scaffolding */
/* ----------- */
.tt-menu,
.gist {
  text-align: left;
}
.typeahead,
.tt-query,
.tt-hint {
  font-size: 20px;
  line-height: 30px;
  outline: none;
}
.typeahead {
  height: 44px;
  overflow: hidden;
  background-color: #fff;
}
.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.tt-hint {
  color: #999;
}
.tt-menu {
  max-width: 100%;
  margin: 0;
  padding: 1px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.tt-suggestion {
  padding: 10px 10px;
  font-size: 18px;
  line-height: 24px;
}
.tt-suggestion > * {
  vertical-align: middle;
}
.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #0097cf;
}
.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;
}
.tt-suggestion p {
  margin: 0;
}
.gist {
  font-size: 14px;
}
/* example specific styles */
/* ----------------------- */
#custom-templates .empty-message {
  padding: 5px 10px;
  text-align: center;
}
#scrollable-dropdown-menu .tt-menu {
  max-height: 150px;
  overflow-y: auto;
}
#rtl-support .tt-menu {
  text-align: right;
}
.tt-highlight {
  font-weight: inherit;
  color: red;
}
.login-box,
.register-box {
  width: 360px;
  margin: 3% auto;
}
.login-logo,
.register-logo {
  font-size: 35px;
  text-align: center;
  margin: 40px;
  font-weight: 300;
}
.login-page,
.register-page {
  background: white;
}
.login-box-body,
.register-box-body {
  background: #fff;
  padding: 20px;
  border-top: 0;
  color: #666;
  border: 1px solid #d2d6de;
}
.content-wrapper,
.right-side {
  min-height: 100%;
  background-color: white;
  z-index: 800;
}
.sidebar-menu,
.main-sidebar .user-panel,
.sidebar-menu > li.header {
  white-space: normal;
  overflow: hidden;
}
.public-content-wrapper {
  margin-left: 0;
}
.public-main-footer {
  margin-left: 0;
}
.logo_auth {
  max-width: 300px;
}
.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-left: -45px;
  margin-top: -25px;
}
.fancybox__content {
  padding: 0;
}
video:not(.vjs-tech) {
  display: none;
}
